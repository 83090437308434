// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // baseURL: 'http://localhost:8001/',
  // baseURL: 'https://torneostarplay.com:8001/',
  baseURL: 'https://api.torneostarplay.com/',
  endpoints: {
    authenticate: 'authenticate',
    users: 'users',
  },
};
