import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import { ToastContainer, toast } from "react-toastify";

//Tabla
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
//footer
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import axios from "axios";
import Swal from "sweetalert2";

import {
  Box, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle,
  FormControl, FormControlLabel, InputLabel,
  MenuItem, Select, TableRow,
  TableHead, TableContainer, TableBody, Table,
  Input,
  TextField, Paper
} from '@mui/material'

import "../sass/Tablas.sass"
import API from "../services/config"

import Settings, { DefaultSettingsT, SettingsT } from '../components/carousel/settings';
import Carousel from 'react-material-ui-carousel';
import '../sass/Carousel.sass';
import { Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#000',
  },
}));
const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n+1)': {
    backgroundColor: '#e7e7e7',

  },
  '&:nth-of-type(2n)': {
    backgroundColor: '#ffffff',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
  margin: '8px 0',
  // hide last border

}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function PrimerTorneo() {
  window.sessionStorage.setItem("one", "true");
  window.sessionStorage.setItem("two", "true");
  window.sessionStorage.setItem("four", "true");
  window.sessionStorage.setItem("three", "false");
  window.sessionStorage.setItem("text1", "");

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([]);
  const [Once, setOnce] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [images, setImages] = useState([]);
  const [items, setItems] = useState([]);

  const [render_coso, setRender_coso] = useState(<></>)

  //premios
  const [premioTexto1, setPremioTexto1] = useState({});
  const [premioTexto2, setPremioTexto2] = useState({});
  const [renderPremioTexto1, setRenderpremioTexto1] = useState(<></>);
  const [renderPremioTexto2, setRenderpremioTexto2] = useState(<></>);

  //banner
  const [srcBanner, setSrcBanner] = useState('')

  function createData2(posicion, usuario, valorApostado, valorPremio) {
    return { posicion, usuario, valorApostado, valorPremio };
  }

  //pagination - footer
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [reloadData, setReloadData] = useState(true);

  useEffect(() => {
    // Traer registros por paginación
    if (reloadData) {
      fractionedDataTable1().then(() => {
        setTimeout(() => {
          Swal.close();
        }, 750)
      })
    } else {
      searchHandleResults()
    }
  }, [page, rowsPerPage, reloadData]);

  const fractionedDataTable1 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let dataTable = await axios.get(
      // API.baseURL + "api/get/primer-torneo/slice/0/50"
      API.baseURL + "api/get/primer-torneo/slice/" + page + "/" + rowsPerPage + ""
    );
    setFilteredData(dataTable.data.primertorneos);
    setTotalRows(dataTable.data.length)

    if (Once.length === 0) {
      setOnce([{}]);
    }

    let subtitulos = await axios.get(
      API.baseURL + 'api/get/subtitulos/id/0'
    )
    setSubtitle(subtitulos.data.subtitulos.primerParrafo)
  };

  // if(filteredData.length < 1 && Once.length === 0){
  //   setOnce([{papa:'hola'}]);
  //   fractionedDataTable1().then(() =>{
  //     setTimeout(() => {
  //       Swal.close();
  //     }, 750)
  //   })
  // }

  //search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let searchWord = '';
      if (searchTerm === '') {
        setPage(0);
        setRowsPerPage(parseInt(10, 10));
        searchWord = '!';
      } else { searchWord = searchTerm; }
      //handleFilter(searchWord);
    }, 450)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const searchHandleResults = () => {
    let searchWord = '';
    if (searchTerm === '') {
      setReloadData(true)
      searchWord = '!';
    } else {
      searchWord = searchTerm;
      handleFilter(searchWord);
    }

  }

  const handleFilter = async (event) => {
    setReloadData(false)
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    /*const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.usuario.toLowerCase().includes(searchWord.toLowerCase());
    });

    i
    }*/
    //---------------------
    //console.log(event);
    const searchWord = event;
    let filterWord;
    const pattern = new RegExp('^[A-Z0-9]+$', 'i');

    setWordEntered(searchWord);

    if (!pattern.test(searchWord)) {
      filterWord = '!'
    } else {
      filterWord = searchWord
    }

    let filteredDataAxi;


    filteredDataAxi = await axios.get(
      // API.baseURL + "api/get/primer-torneo/search/" + filterWord
      API.baseURL + "api/get/primer-torneo/search/" + filterWord + "?page=" + page + "&limit=" + rowsPerPage + ""
    );

    /*filteredDataAxi = await axios.get(
      API.baseURL + "api/get/segundo-torneo/search/" + filterWord
    );*/

    setTotalRows(filteredDataAxi.data.length)
    setFilteredData(filteredDataAxi.data.resultado)

    Swal.close();
  };

  // Avoid a layout jump when reaching the last page with empty rows.


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log('The link was clicked.');
  };

  const handleDonwloadPDF = async (e) => {
    e.preventDefault();
    const response = await axios.get(API.baseURL + 'api/get/pdf/all');
    const PDFs = response.data.pdfs

    if (PDFs && PDFs.length > 0 && PDFs[0]) {
      toast.success(`Descargando pdf exitosamente`);
      const decodedData = PDFs[0].pdfData
      //setBase64STR(decodedData);
      const downloadLink = document.createElement("a");

      downloadLink.href = decodedData;

      downloadLink.download = "reglas.pdf";

      downloadLink.click();
    } else {
      toast.info(`¡No hay pdf por descargar!`);
    }
    /*fs.writeFile(outputPath, decodedData, (error) => {
      if (error) {
        console.error('Error writing the PDF file:', error);
      } else {
        console.log('PDF file saved successfully at', outputPath);
      }
    });*/
  }

  const tablaTorneo = () => {
    let auxRows = []

    filteredData.forEach(element => {
      auxRows.push(createData2(element.posicion, element.usuario, element.valorApostado, element.valorPremio, 0));
    });

    const rows = auxRows
    let newFontSize = 14

    if (window.innerWidth < 1100) {
      newFontSize = 14
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: newFontSize }} className='row_table' align="center"><h4>Posición</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Usuario</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Puntaje</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Premio</h4></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 && rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center">
                  <h4>{row.posicion}</h4>
                </StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.usuario}</h4></StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.valorApostado}</h4></StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.valorPremio}</h4></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {window.innerWidth > 992
                ? <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={4}
                  count={totalRows}
                  labelRowsPerPage={""}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Celdas visibles',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
                : window.innerHeight < 400
                  ? <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    colSpan={4}
                    count={totalRows}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Celdas visibles',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                  : <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    colSpan={4}
                    count={totalRows}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Celdas visibles',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
              }

            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  const renderImagenes = (data_images) => {
    console.log(data_images)
    console.log(data_images == 0)
    setRender_coso(
      <>
        {data_images.length == 0 ?
          <></>
          :
          <div className='carousel_image_div'>
            <img
              className='carousel_image'
              src={data_images[0].imageData}
              alt="Equipo vs Equipo"
            />
          </div>

        }
      </>
    )
  }

  const cargarPremios = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    const response = await axios.get(API.baseURL + "api/get/premio/all").then((e) => {
      if (e.data.premios.length == 0) {
        //setDataSelect([{hola:'hola'}])
        Swal.close();
      } else {
        //setDataSelect(e.data.premios)
        e.data.premios.map(data => {
          if (data.id == 0) {
            console.log(data.valoresPremios)
            setPremioTexto1(data)
            const auxRender = <>
              <DialogContentText>
                Puesto 1: {data.valoresPremios[0].premio}
              </DialogContentText>
              <DialogContentText>
                Puesto 2: {data.valoresPremios[1].premio}
              </DialogContentText>
              <DialogContentText>
                Puesto 3: {data.valoresPremios[2].premio}
              </DialogContentText>
            </>
            setRenderpremioTexto1(auxRender)
          }
          else if (data.id == 1) {
            setPremioTexto2(data)
            const auxRender = <>
              <DialogContentText>
                Puesto 1: {data.valoresPremios[0].premio}
              </DialogContentText>
              <DialogContentText>
                Puesto 2: {data.valoresPremios[1].premio}
              </DialogContentText>
              <DialogContentText>
                Puesto 3: {data.valoresPremios[2].premio}
              </DialogContentText>
            </>
            setRenderpremioTexto2(auxRender)
          }
        })
        Swal.close();
      }
    })
  }

  const cargarImagenes = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let data = await axios.get(API.baseURL + "api/get/imagen/all").then(e => {
      if (e.data.imagen.length > 0) {

        console.log(e.data.imagen)
        renderImagenes(e.data.imagen)
        setImages(e.data.imagen)

        let auxItems = []
        e.data.imagen.map(image => {

          let dataImage = {
            imageData: image.imageData,
            imageName: image.imageName,
          }

          auxItems.push(dataImage)

        })

        setItems(auxItems)

        Swal.close();
      }
    });

    let data_imagen = await axios.get(API.baseURL + "api/get/banner/all").then(e => {
      if (e.data.banner.length > 0) {
        setSrcBanner(e.data.banner[0].bannerData)
      }
      Swal.close();
    })

  }

  useEffect(() => {
    cargarImagenes()
    cargarPremios()
  }, [])

  function Project({ item }) {
    return (
      <Paper
        className="Project"
        elevation={10}
      >
        <img className='carousel_image' src={item.imageData} />
      </Paper>
    )
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event) => {
    /*setImageNameValue(
      event.target.value,
    );*/
  };

  return (
    <>
      <ToastContainer style={{ paddingBottom: "30rem" }} />
      <body className='body_primer_torneo'>
        <div className='container_tablas'>
          <div className='content_area group'>
            <div className='main_area_torneo'>
              <div className='nav'>
                <grid container spacing={0} className='navbar'>

                  <aside style={{ width: '5%', marginLeft: '-15%', boxSizing: 'border-box', float: 'left' }}>
                    <Navbar id="navbarsito" />
                  </aside>
                  <div style={{ width: '50%', marginLeft: '20%', boxSizing: 'border-box', float: 'left' }}>
                    <a href="/" >
                      <img

                        src={require("../images/logo_starplay.png")}
                        width="220px"

                        className="d-inline-block align-top"
                        alt="Logo de eliminatorias StarPlay"
                      />
                    </a>
                  </div>
                  {
                    window.innerWidth < 992 && window.innerHeight > 820 ?
                      <></>
                      :
                      <aside className='right_navbar'>
                        <grid container spacing={4} className='navbar'>
                          <div style={{ marginLeft: '0%' }}>
                            {
                              //'pagina de reglas'
                            }
                            <a className='a_navbar' onClick={handleClickOpen}>
                              <h4>Premios</h4>
                            </a>
                          </div>

                          <div style={{ marginLeft: '20%' }}>
                            {
                              //'Descarga de PDF'
                            }
                            <a className='a_navbar' href='_PDF_reglas' onClick={handleDonwloadPDF}>
                              <h4>Reglas</h4>
                            </a>
                          </div>
                        </grid>
                      </aside>
                  }


                </grid>

                {window.innerWidth > 992}

              </div>


              {
                window.innerWidth < 992 && window.innerHeight > 820 ?
                  <aside className='right_navbar'>
                    <grid container spacing={4} className='navbar'>
                      <div style={{ marginLeft: '0%' }}>
                        {
                          //'pagina de reglas'
                        }
                        <a className='a_navbar' onClick={handleClickOpen}>
                          <h4>Premios</h4>
                        </a>
                      </div>

                      <div style={{ marginLeft: '50%' }}>
                        {
                          //'Descarga de PDF'
                        }
                        <a className='a_navbar' href='_PDF_reglas' onClick={handleDonwloadPDF}>
                          <h4>Reglas</h4>
                        </a>
                      </div>
                    </grid>
                  </aside>
                  :
                  <></>
              }

              <div className='imagenes_promocion'>

                {/*renderprueba()*/}
                {srcBanner && (
                  <img
                    src={srcBanner}
                    width="100%"
                    alt="Banner"
                  />
                )}

              </div>
              <div className='over_main_area_tabla' >

                <h2 className='subtitulo_tabla'>{subtitle.toUpperCase()}</h2>
                <h4 className='h4_title'>
                  Ingresa tu correo electrónico
                </h4>
                <div>
                  <div className='buscar_input_div'>
                    <Input className='buscar_input'
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder='Buscar...'
                    />
                  </div>
                  <div className='buscar_button_div'>
                    <Button className='buscar_button' onClick={searchHandleResults}>
                      Buscar
                    </Button>
                  </div>
                </div>
              </div>

              <div className='main_area_tabla'>






                <div className='area_tablas'>
                  {tablaTorneo()}
                </div>
              </div>
              <aside className='right_area_torneo'>
                <div className=''>
                  <div className='carousel' style={{ color: "#494949" }}>

                    <Carousel
                      className="SecondExample"
                      {...DefaultSettingsT}
                    >
                      {
                        items.map((item, index) => {
                          return <Project item={item} key={index} />
                        })
                      }
                    </Carousel>

                    {
                      //<Settings settings={DefaultSettingsT} setSettings={DefaultSettingsT} />
                    }

                  </div>
                </div>
                {/*<a href="/" >
                    <img
                        src={require("../images/prueba/StarPay Banner Horizontal.jpg")}
                        height="500px"
                        width="15%"
                    />
                  </a>*/}
              </aside>
            </div>
          </div>
          <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>
              { //(premioTexto2 == {})
                (premioTexto1 == {})
                  ? <></>
                  : premioTexto1.titulo
              }
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ marginBottom: '2rem' }}>
                { //(premioTexto2 == {})
                  (premioTexto1 == {})
                    ? <></>
                    : premioTexto1.descripcion
                }
              </DialogContentText>
              {/**puedes colocar un map aca con un arreglo de cuantas posiciones quieren mostrar */}

              {
                (premioTexto1 == {})
                  ? <></>
                  : renderPremioTexto1
              }

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cerrar</Button>
            </DialogActions>
          </Dialog>

        </div>
        <footer>

        </footer>
      </body>
    </>
  );
}

export default PrimerTorneo;